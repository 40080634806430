<template>
  <div class="page">
    <div class="fab" @click="clickAddRider">
      <van-icon name="plus" color="white" />
    </div>
    <van-empty description="还没有数据哦" v-if="!loading && finished && list.length === 0" style="position: fixed;top: 30vh;left: 0;right: 0;"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-collapse v-model="activeGroup" accordion>
          <van-collapse-item v-for="item in list" :key="item.title" :name="item.title">
            <template #title>
              <div>
                {{ item.title }}
                <van-tag style="margin-left: 10px" plain type="primary">{{ item.riders.length }}</van-tag>
                <van-tag style="margin-left: 10px" plain type="danger" v-show="item.riders.filter(it => it.overtime).length > 0">{{ item.riders.filter(it => it.overtime).length }}</van-tag>
              </div>
            </template>
            <van-swipe-cell v-for="rider in item.riders" :key="rider.id" right-width="120">
              <van-cell @click="clickRider(rider.id)"  :title="rider.username" :label="'今日单量：' + rider.todayOrderCount">
                <template #default>
                  <label style="color: red" v-if="rider.overtime">{{rider.exp}}</label>
                  <label v-else>{{rider.exp}}</label>
                </template>
              </van-cell>
              <template #right>
                <div style="display: flex;height: 100%">
                  <div class="del" @click="onEdit(rider)" style="background-color: dodgerblue">编辑</div>
                  <div class="del" @click="onDelete(rider)">删除</div>
                </div>
              </template>
            </van-swipe-cell>
          </van-collapse-item>
        </van-collapse>

<!--        <van-cell v-for="item in list" :key="item.deviceId" :title="item.name" :label="'今日单量：' + item.todayOrderCount" />-->
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="showAdd" style="width: 80%;border-radius: 10px;padding-top: 20px">
      <div style="text-align: center;font-weight: bold">{{ showEdit ? '编辑' : '添加'}}骑手信息</div>
      <van-form>
        <van-field name="area" v-model="form.area" label="区域" :readonly="areaReadonly" :clickable="areaClickable" center @click="clickPickArea" placeholder="请选择" label-width="40px">
          <template #button>
            <van-button size="small" type="primary" @click.stop="clickAddArea">新增</van-button>
          </template>
        </van-field>
        <van-field name="username" v-model="form.username" label="名称" placeholder="请填写" label-width="40px"/>
        <van-field name="token" v-model="form.token" label="token" placeholder="请填写" label-width="40px"/>
        <div style="display: flex;justify-content: center;align-items: center">
          <van-button native-type="submit" round type="info" style="width: 200px;margin: 20px 0" @click="onSubmit">提交</van-button>
        </div>
      </van-form>
    </van-popup>

    <van-popup v-model="showAreaList" position="bottom">
      <van-picker
          show-toolbar
          :columns="areaList"
          @confirm="onConfirmArea"
          @cancel="showAreaList = false"
      />
    </van-popup>

    <van-popup v-model="showRiderOrderCount" position="top">
      <van-cell-group style="width: 86vw;">
        <van-cell v-for="item in riderOrderInfoList" :key="item" :title="item.device_name" :value="item.amount" />
      </van-cell-group>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "rider",
  data() {
    return {
      showRiderOrderCount: false,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      showAdd: false,
      showEdit: false,
      form: {area: '', username: '', token: ''},
      selectedItem: null,

      areaReadonly: true,
      areaClickable: true,
      showAreaList: false,
      areaList: [],
      activeGroup: '',
      riderOrderInfoList: []
    }
  },
  created() {
    // this.fetchAreaList()
  },
  methods: {
    /*fetchAreaList() {
      this.$http.get('mock/area').then(res => {
        this.areaList = res.data
      })
    },*/
    clickRider(riderId) {
      console.log(riderId)
      this.$http.get('mock/riderInfo/' + riderId).then(res => {
        if (res.data && res.data.length) {
          this.riderOrderInfoList = res.data
          this.showRiderOrderCount = true
        } else {
          this.$toast('没有数据')
        }
      })
    },
    clickAddArea() {
      this.areaClickable = false
      this.areaReadonly = false
    },
    clickAddRider() {
      this.form = {area: '', username: '', token: ''}
      this.showAdd = true
      this.showEdit = false
    },
    clickPickArea() {
      if (!this.areaReadonly) return
      this.showAreaList = true
    },
    onConfirmArea(area) {
      this.form.area = area
      this.showAreaList = false
    },
    onSubmit() {
      if (this.form.area === '') return
      if (this.form.username === '') return
      if (this.form.token === '') return
      this.$toast.loading()
      if (this.showEdit) {
        if (this.selectedItem) this.form.id = this.selectedItem.id
        this.$http.put('mock/riderInfo', this.form).then(res => {
          this.$toast.clear(true)
          this.showAdd = false
          this.showEdit = false
          if (res.data === 'good') {
            this.selectedItem = null
            this.onLoad()
          }
        })
      } else {
        this.$http.post('mock/riderInfo', this.form).then(res => {
          this.$toast.clear(true)
          this.showAdd = false
          this.showEdit = false
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }
    },
    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      this.$http.get('mock/riderInfo').then(res => {
        this.loading = false
        res.data.forEach(it => {
          // const date = new Date(JSON.parse(window.atob(it.token.split('.')[1])).exp * 1000)
          const date = new Date(it.exp)
          it.date = date
          // it.exp = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
          it.overtime = date.getTime() < Date.now()
        })
        res.data.sort((a, b) => {
          return a.date - b.date
        })

        let group = new Map()
        res.data.forEach(it => {
          if (group.has(it.area)) {
            group.get(it.area).push(it)
          } else {
            group.set(it.area, [it])
          }
        })
        let groupArray = []
        this.areaList = []
        group.forEach((value, key) => {
          groupArray.push({title: key, riders: value})
          this.areaList.push(key)
        })
        this.list = groupArray

        this.finished = true
        this.refreshing = false
        this.$bus.$emit('data-refresh', {type:'rider', amount:this.list.length})
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.finished = true
        this.refreshing = false
      })
    },
    onDelete(item) {
      this.$dialog({title:'确定删除骑手 ' + item.username, showCancelButton: true}).then(() => {
        this.$toast.loading({mask: true})
        this.$http.delete('mock/riderInfo/' + item.id).then(res => {
          this.$toast.clear(true)
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }).catch(() => {
        console.log('cancel')
      })
    },
    onEdit(item) {
      this.showEdit = true
      this.showAdd = true
      this.selectedItem = item
      this.form.username = item.username
      this.form.token = item.token
      this.form.area = item.area
    }
  }
}
</script>

<style scoped>
.del {
  background-color: red;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.all-room {
  height: calc(100% - 50px);
}

</style>
